import View from "./view";
import navbarLogo from "./../../img/Logos/logo-aufgehuebscht-simple.png";

class navbarView extends View {
  _parentElement = document.querySelector(".navbar");

  handleDropdown() {
    const navBtns = document.querySelectorAll(".navbar__dropdown--btn");
    const navCheckbox = document.querySelector(".navbar__checkbox");
    const navCheckboxIcon = document.querySelector(".navbar__icon");

    // close dropdown when dropdown button was clicked
    navBtns.forEach((btn) => {
      btn.addEventListener("click", function (e) {
        e.preventDefault();
        navCheckbox.checked = false;
      });
    });
  }

  _generateMarkup(home) {
    const subPageURLs = [
      "agb",
      "angebotsuebersicht",
      "booking",
      "contact",
      "cookieSettings",
      "error",
      "errorContact",
      "impressum",
      "privacy",
      "schulungen",
      "success",
      "successContact",
    ];

    let targetURL = "";
    let checkSumURL = 0;

    subPageURLs.forEach((url) => {
      if (window.location.href.includes(url)) {
        checkSumURL += 1;
      }
    });

    if (checkSumURL > 0) {
      targetURL = "index.html";
    } else {
      targetURL = "";
    }

    return `
      <a href="/">
        <img
          src="${navbarLogo}"
          alt="Logo aufgehuebscht Kosmetik"
          class="navbar__logo"
        />
      </a>
      <div class="navbar__section-buttons">
        <a
          href="${targetURL}#behandlungen"
          class="btn__simple navbar__btn--section smooth-scrolling"
          >Angebot</a
        >
        <a
          href="${targetURL}#produkte"
          class="btn__simple navbar__btn--section smooth-scrolling"
          >Produkte</a
        >
        <a
          href="${targetURL}#about-me"
          class="btn__simple navbar__btn--section smooth-scrolling"
          >Über mich</a
        >
      </div>



      <div class="navbar__nav-dropdown">
        <input type="checkbox" class="navbar__checkbox" id="navi-toggle" />

        <label for="navi-toggle" class="navbar__button--open">
          <span class="navbar__icon">&nbsp;</span>
        </label>

        <div class="navbar__nav-dropdown--content">
          <a
            href="${targetURL}#behandlungen"
            class="btn__simple navbar__btn--section smooth-scrolling navbar__dropdown--btn"
            >Angebot</a
          >
          <a
            href="${targetURL}#produkte"
            class="btn__simple navbar__btn--section smooth-scrolling navbar__dropdown--btn"
            >Produkte</a
          >
          <a
            href="${targetURL}#about-me"
            class="btn__simple navbar__btn--section smooth-scrolling navbar__dropdown--btn"
            >Über mich</a
          >
        </div>
      </div>`;
  }
}

export default new navbarView();

    //   <a
    //   href="angebotsuebersicht.html"
    //   class="btn navbar__btn--cta"
    //   >Buche deine Behandlung</a
    // >
