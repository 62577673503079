"use strict";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "litepicker-polyfills-ie11";

import * as model from "./model.js";

import { sendMail, sendContactFormMail } from "./helpers.js";
import { EMAIL_SECRET } from "./config.js";

import reviewSliderView from "./views/reviewSliderView.js";
import smoothScrollingView from "./views/smoothScrollingView.js";
import smoothRevealView from "./views/smoothRevealView.js";
import offerSmoothRevealView from "./views/offerSmoothRevealView.js";
import productIconsSmoothRevealView from "./views/productIconsSmoothRevealView.js";
import contactFormView from "./views/contactFormView.js";
import navbarView from "./views/navbarView.js";
import footerView from "./views/footerView.js";
import bookingView from "./views/bookingView.js";
import offerView from "./views/offerView.js";
import offerScrollbarView from "./views/offerScrollbarView.js";
import calendarView from "./views/calendarView.js";
import checkoutView from "./views/checkoutView.js";
import imgView from "./views/imgView.js";
// import bannerView from "./views/bannerView.js"; // baby banner

import offerData from "../data/angebot.json";

// ======= DECLARATIONS FOR FULL INTIALIZATION =========
//

const controlBookingAdding = function (slug) {
  const selectedBooking = offerData.find((entry) => entry.slug === slug);

  model.addBooking(selectedBooking);
};

const controlBookingDeletion = async function (slug) {
  model.deleteBooking(slug);
  calendarView.renderSpinner();
  await model.getFreeTimeSlots();
  calendarView.renderTimeSlots(
    model.state.availableTimeSlots,
    model.state.selectedDate
  );
  calendarView.handleDateSelection(handleDateSelectionAdding);
};

const handleDateSelectionAdding = function (startTime, endTime) {
  model.addSelectedTimeSlot(startTime, endTime);
};

const controlSelectedDate = async function (date) {
  try {
    calendarView.renderSpinner();
    model.addDate(date);
    await model.getFreeTimeSlots();
    calendarView.renderTimeSlots(model.state.availableTimeSlots, date);
    calendarView.handleDateSelection(handleDateSelectionAdding);
  } catch (err) {
    console.error(err);
  }
};

const controlCheckout = async function (
  firstName,
  lastName,
  email,
  phone,
  message
) {
  checkoutView.renderSpinner();

  const bookingData = {
    firstName,
    lastName,
    email,
    phone,
    message,
    date: model.state.selectedDate,
    startTime: model.state.selectedTimeSlot.startTime,
    endTime: model.state.selectedTimeSlot.endTime,
    bookings: model.state.bookings,
    summaries: model.state.summaries,
    secret: EMAIL_SECRET,
  };

  const mailResponse = await sendMail(bookingData);

  if (mailResponse.status === "success") {
    window.location.href = "success.html";
    model.clearBookings();
  } else {
    window.location.href = "error.html";
  }
};

const controlContactRequest = async function (
  firstName,
  lastName,
  email,
  phone,
  message
) {
  contactFormView.renderSpinner();

  const requestData = {
    firstName,
    lastName,
    email,
    phone,
    message,
    secret: EMAIL_SECRET,
  };

  const mailResponse = await sendContactFormMail(requestData);

  if (mailResponse.status === "success") {
    window.location.href = "successContact.html";
  } else {
    window.location.href = "errorContact.html";
  }
};

// ========= FULL INITIALIZATION ============
//

const init = function () {
  navbarView.render(true);
  navbarView.handleDropdown();
  footerView.render(true);
  smoothScrollingView.handleSmoothScrolling();
  contactFormView.render(true);
  contactFormView.handleContactForm(controlContactRequest);
  imgView.lazyLoadingImg();

  const curLocation = window.location.href;

  if (curLocation.includes("angebotsuebersicht")) {
    offerView.renderOffer(offerData);
    offerView.addHandlerClick(controlBookingAdding);
    offerView.handleCardFlip();
    offerScrollbarView.handleOfferScrollbar();
  } else if (curLocation.includes("booking")) {
    bookingView.renderBookings(model.state.bookings);
    bookingView.renderSummary(model.state.summaries);
    bookingView.addHandlerDelete(controlBookingDeletion, model.state.summaries);
    calendarView.addHandlerCalendar(controlSelectedDate);
    calendarView.renderTimeSlots(model.state.availableTimeSlots);
    checkoutView.addHandlerCheckout(controlCheckout);
  } else {
    reviewSliderView.render(true);
    smoothRevealView.handleSmoothReveal(model.state.pageViewed);
    offerSmoothRevealView.offerSmoothReveal();
    productIconsSmoothRevealView.productIconsSmoothReveal();
  }

  bannerView.render(true);
  bannerView.revealBanner(model.state.websiteVisit.visitedWebsite);
};

// initialization of controller
init();
