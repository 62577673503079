import infoIcon from "../../img/Icons/info.png";

class offerView {
  addHandlerClick(handler) {
    const allBookingBtns = document.querySelectorAll(
      ".card__main--btn-booking"
    );

    allBookingBtns.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        e.preventDefault();

        handler(e.target.dataset.slug);

        window.location.href = 'booking.html';
      })
    );
  }

  renderOffer(offerData) {
    const containerGesicht = document.querySelector(
      ".angebot__accordion--gesicht"
    );
    const containerKoerper = document.querySelector(
      ".angebot__accordion--koerper"
    );
    const containerNagel = document.querySelector(".angebot__accordion--nagel");
    const containerMakeUp = document.querySelector(
      ".angebot__accordion--make-up"
    );

    offerData.forEach((obj) => {
      let markup;

      if (obj.category === "face") {
        markup = this._generateOfferMarkup(obj, "Gesicht");
        containerGesicht.insertAdjacentHTML("beforeend", markup);
      }

      if (obj.category === "nail") {
        markup = this._generateOfferMarkup(obj, "Nägel");
        containerNagel.insertAdjacentHTML("beforeend", markup);
      }

      if (obj.category === "body") {
        markup = this._generateOfferMarkup(obj, "Körper");
        containerKoerper.insertAdjacentHTML("beforeend", markup);
      }

      if (obj.category === "makeup") {
        markup = this._generateOfferMarkup(obj, "Make-Up");
        containerMakeUp.insertAdjacentHTML("beforeend", markup);
      }
    });
  }

  handleCardFlip() {
    const allCardDetailBtns = document.querySelectorAll(
      ".card__main--btn-details"
    );
    const allCardCloseBtns = document.querySelectorAll(".card__back--close");

    allCardDetailBtns.forEach(this._cardFlipCbFunc);
    allCardCloseBtns.forEach(this._cardFlipCbFunc);
  }

  _cardFlipCbFunc(btn) {
    btn.addEventListener("click", (e) => {
      e.preventDefault();

      let cardFront;
      let cardBack;

      if (e.target.classList.contains("card__main--btn")) {
        cardFront = e.target.closest(".card__main");
        cardBack = e.target
          .closest(".card__container")
          .querySelector(".card__back");
      } else {
        cardBack = e.target.closest(".card__back");
        cardFront = e.target
          .closest(".card__container")
          .querySelector(".card__main");
      }

      cardFront.classList.toggle("card__main--hidden");
      cardBack.classList.toggle("card__back--active");
    });
  }

  _generateOfferMarkup(inputData, category) {
    return `<div class="card__container">
        <div class="card__main card__side">
          <h4 class="card__main--subtitle">${category}</h4>
          <h3 class="card__main--heading">${inputData.title}</h3>
    
          <div class="card__main--btn-wrapper">
            <a href="#" class="card__main--btn card__main--btn-details"
              >Details</a
            >

          </div>
    
          <div class="card__main--facts">
            <div class="card__main--factline">
              <span class="card__main--factline-value">${
                inputData.duration
              } min</span>
              <span class="card__main--factline-description">Dauer</span>
            </div>
    
            <div class="card__main--factline">
              <span class="card__main--factline-value">${
                inputData.price
              } €</span>
              <span class="card__main--factline-description">Preis</span>
            </div>
          </div>
    
          ${
            inputData.onSale === "ja"
              ? '<div class="card__main--banner">Aktion</div>'
              : ""
          }
    
          ${
            inputData.new === "ja"
              ? '<div class="card__main--banner">Neu</div>'
              : ""
          }
    
        </div>
    
        <div class="card__back card__side">
          <div class="card__back--close"></div>
    
          <div class="card__details">
          ${inputData.description}
          </div>
    
          ${
            inputData.warning === ""
              ? ""
              : `<div class="card__warning"><img src="${infoIcon}" alt="" class="card__warning--icon"/><span class="card__warning--text">${inputData.warning}</span></div>`
          }
    
        </div>
      </div>`;
  }
}

export default new offerView();

{/* <a href="#" data-slug="${
  inputData.slug
}" class="card__main--btn card__main--btn-booking"
  >Buchen</a
> */}
